import { PROJECT_SUCCESS, PROJECT_FAILURE, PROJECT_SUCCESS_SECOND, PROJECT_SUCCESS_THREE } from "redux/constants/index";
import { onSuccess, onFailure, onSuccessTwo, onSuccessThree } from "redux/reducers/common";

const initStat = { data: [], dataTwo: [], dataThree:[] ,  error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
  switch (action.type) {
    case PROJECT_SUCCESS:
      return onSuccess(state, action);
    case PROJECT_FAILURE:
      return onFailure(state, action);
    case PROJECT_SUCCESS_SECOND:
      return onSuccessTwo(state, action);
    case PROJECT_SUCCESS_THREE:
      return onSuccessThree(state, action);
    default:
      return { ...state };
  }
};
