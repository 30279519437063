export const onSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
    error: false,
  };
};

export const onSuccessAllData = (state, action) => {
  return {
    ...state,
    allData: action.payload,
    isLoading: false,
  };
};

export const onSuccessLabelData = (state, action) => {
  return {
    ...state,
    data3: action.payload,
    isLoading: false,
  };
};

export const onSuccessTagData = (state, action) => {
  return {
    ...state,
    data4: action.payload,
    isLoading: false,
  };
};

export const onSuccessTagData2 = (state, action) => {
  return {
    ...state,
    data5: action.payload,
    isLoading: false,
  };
};

export const onSuccessTwo = (state, action) => {
  return {
    ...state,
    dataTwo: action.payload,
    isLoading: false,
  };
};
export const onSuccessThree = (state, action) => {
  return {
    ...state,
    dataThree: action.payload,
    isLoading: false,
  };
};

export const onFailure = (state, action) => {
  return { ...state, isLoading: false, errorData: action.payload, error: true };
};

export const onFailureAllData = (state, action) => {
  return { ...state, isLoading: false, errorData: action.payload, error: true };
};