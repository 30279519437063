export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const ADMIN_DASHBAORD_SUCCESS = "ADMIN_DASHBAORD_SUCCESS";
export const ADMIN_DASHBAORD_FAILURE = "ADMIN_DASHBAORD_FAILURE";

export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAILURE = "REPORT_FAILURE";

export const PROJECT_SUCCESS = "PROJECT_SUCCESS";
export const PROJECT_FAILURE = "PROJECT_FAILURE";
export const PROJECT_SUCCESS_ALL_PROJECTS = "PROJECT_SUCCESS_ALL_PROJECTS";


export const PROJECT_SUCCESS_ID = "PROJECT_SUCCESS_ID";
export const PROJECT_FAILURE_ID = "PROJECT_FAILURE_ID";

export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";

export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FAILURE = "ADMIN_FAILURE";

export const PROJECT_REQUEST_SUCCESS = "PROJECT_REQUEST_SUCCESS";
export const PROJECT_REQUEST_FAILURE = "PROJECT_REQUEST_FAILURE";

export const PROJECT_SUCCESS_LANGUAGE = 'PROJECT_SUCCESS_LANGUAGE';
export const PROJECT_SUCCESS_SECOND = "PROJECT_SUCCESS_SECOND";
export const PROJECT_SUCCESS_THREE = "PROJECT_SUCCESS_THREE";

export const PROJECT_SUCCESS_TWO = 'PROJECT_SUCCESS_TWO';
export const PROJECT_FAILURE_TWO = 'PROJECT_FAILURE_TWO';

export const PROJECT_SUCCESS_FILLER_DATA = "PROJECT_SUCCESS_FILLER_DATA";
export const PROJECT_FAIL_FILLER_DATA = "PROJECT_FAIL_FILLER_DATA";

export const ADD_FILLER_DATA_SUCCESS = "ADD_FILLER_DATA_SUCCESS";
export const ADD_FILLER_DATA_FAIL = "ADD_FILLER_DATA_FAIL";

export const VENDOR_SUCCESS = "VENDOR_SUCCESS";
export const VENDOR_SUCCESS_TWO = "VENDOR_SUCCESS_TWO";
export const VENDOR_FAILURE = "VENDOR_FAILURE";

export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_FAILURE = "ADD_VENDOR_FAILURE";

export const PROJECT_SUCCESS_LABEL_DATA = "PROJECT_SUCCESS_LABEL_DATA";
export const PROJECT_SUCCESS_TAG_DATA = "PROJECT_SUCCESS_TAG_DATA";
export const PROJECT_SUCCESS_TAG_TWO_DATA = "PROJECT_SUCCESS_TAG_TWO_DATA"

