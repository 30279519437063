import { ADD_VENDOR_FAILURE, ADD_VENDOR_SUCCESS } from "redux/constants/index";
import { onFailureAllData, onSuccessAllData } from "redux/reducers/common";

const initStat = { data: [], allData: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
    switch (action.type) {
        case ADD_VENDOR_FAILURE:
            return onSuccessAllData(state, action);
        case ADD_VENDOR_SUCCESS:
            return onFailureAllData(state, action);
        default:
            return { ...state };
    }
};
