import React from "react";
import { connect } from "react-redux";
import { Button, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, ArrowLeftOutlined, HomeOutlined } from "@ant-design/icons";
import Logo from "./Logo";
// import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/yugo/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH, VENDOR_NAV_TOP } from "constants/ThemeConstant";
import utils from "utils";
import { useHistory } from "react-router-dom";
import MenuContent from "./MenuContent";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const history = useHistory();
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme } = props;
  // const [searchActive, setSearchActive] = useState(false);

  // const onSearchActive = () => {
  //   setSearchActive(true);
  // };

  // const onSearchClose = () => {
  //   setSearchActive(false);
  // };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} mobileLogo={history.location.pathname === '/home/dashboard' && isMobile ? true : false} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          {
            history.location.pathname === '/home/dashboard' ? null :
              <>
                {history.location.pathname.includes('/app/vendor')
                  ?
                  <>
                    {/* <div className="top-nav-menu">
                      <span><HomeOutlined /></span>
                      <span>Dashboard</span>
                    </div> */}
                    <div className="top-nav-vendor">
                      <MenuContent
                        style={{ backgroundColor: "#ffffff" }}
                        type={VENDOR_NAV_TOP}
                        {...props}
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className="nav-left">
                      <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                        {isNavTop && !isMobile ? null : (
                          <li
                            className="ant-menu-item ant-menu-item-only-child"
                            onClick={() => {
                              onToggle();
                            }}
                          >
                            {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="ant-page-header-back">
                      <div role="button" tabIndex="0" className="ant-page-header-back-button ml-3" aria-label="Back" style={{ border: '0px', background: 'transparent', padding: '0px', lineHeight: '4.3', display: 'inline-block' }}>
                        <Button title='Go Back' type="primary" size="small" ghost role="img" onClick={() => (history.length) && history.go(-1)} aria-label="arrow-left" className="anticon anticon-arrow-left">
                          <ArrowLeftOutlined />
                        </Button>
                      </div>
                    </div>
                  </>
                }
              </>
          }
          <div className="nav-right">
            {/* <NavNotification /> */}
            {(history.location.pathname !== '/home/dashboard' && !history.location.pathname.includes("app/vendor")) ?
              <HomeOutlined title="Go to home" style={{ lineHeight: '85px', fontSize: '26px', marginRight: '10px', cursor: 'pointer' }} onClick={() => history.push('/home/dashboard')} />
              : null}
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction };
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);
