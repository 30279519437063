import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import LoginReducer from "redux/reducers/yugo/login/login.reducer";
import LogoutReducer from "redux/reducers/yugo/login/logout.reducer";
import DashboardReducer from "redux/reducers/yugo/dashboard/dashboard.reducer";
import ProjectReducer from 'redux/reducers/yugo/project/project.reducer';
import languageReducer from 'redux/reducers/yugo/project/language.reducer';
import UserReducer from 'redux/reducers/yugo/user/user.reducer';
import AdminReducer from 'redux/reducers/yugo/admin/admin.reducer';
import ProjectRequestReducer from 'redux/reducers/yugo/project_request/project_request.reducer';
import TranslationReducer from 'redux/reducers/translation/tr-project.reducer';
import AnnotationReducer from 'redux/reducers/annotation/annotation-project.reducer';
import TRProjectReducer from 'redux/reducers/transcription/project/project.reducer';
import MasterReducer from 'redux/reducers/transcription/master/master.reducer';
import VendorReducer from "redux/reducers/transcription/vendor/vendor.reducer";
import VendorAddReducer from "redux/reducers/transcription/vendor/addvendor.reducer";

const reducers = combineReducers({
  languageReducer: languageReducer,
  vendorAddReducer: VendorAddReducer,
  vendorReducer: VendorReducer,
  masterReducer: MasterReducer,
  theme: Theme,
  auth: Auth,
  loginReducer: LoginReducer,
  logoutReducer: LogoutReducer,
  dashboardReducer: DashboardReducer,
  projectReducer: ProjectReducer,
  projectReducerTR: TRProjectReducer,
  userReducer: UserReducer,
  adminReducer: AdminReducer,
  projectRequestReducer: ProjectRequestReducer,
  translationReducer: TranslationReducer,
  annotationReducer: AnnotationReducer,
});

export default reducers;
