import { sortBy } from "lodash";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj) => {
  return obj && Object.keys(obj).length > 0;
};

export const getQueryParams = (location, value) => {
  const params = new URLSearchParams(location.search);
  return params.get(value);
};

export const getUserId = () => {
  return secureLocalStorage.getItem("user_id");
};

export const getAdminStatus = () => {
  return secureLocalStorage.getItem("admin_status");
};

export const getVendorStatus = () => {
  return secureLocalStorage.getItem("login_vendor");
};

export const getUserPermission = () => {
  return secureLocalStorage.getItem("users_permission");
};

export const getProjectPermission = () => {
  return secureLocalStorage.getItem("project_permission");
};

export const getUserName = () => {
  return secureLocalStorage.getItem("name");
};

export const getUserEmail = () => {
  return secureLocalStorage.getItem("email");
};



export const getUserToken = () => {
  return secureLocalStorage.getItem("token");
};

export const isValidToken = (token) => {
  return secureLocalStorage.getItem("token") === token;
};

export const removeAllStorageItems = () => {
  secureLocalStorage.removeItem("token");
  secureLocalStorage.removeItem("user_id");
  secureLocalStorage.removeItem("admin_status");
  secureLocalStorage.removeItem("project_permission");
  secureLocalStorage.removeItem("users_permission");
  secureLocalStorage.removeItem("user_fbai");
  secureLocalStorage.removeItem("name");
  secureLocalStorage.removeItem("email");
  secureLocalStorage.removeItem('YugoTranscription');
  secureLocalStorage.removeItem('isAuthenticated');
};

export const setUrl = () => {
  const devUrl = process.env.REACT_APP_API;
  const prodUrl = process.env.REACT_APP_API;
  return process.env.NODE_ENV === "development" ? devUrl : prodUrl;
};

export const getProjectRedirectUrl = (type) => {
  let url = '';
  switch (type) {
    case 1:
      url = "/app/prompt-project?type=1";
      break;
    case 2:
      url = "/app/prompt-project?type=1";
      break;
    case 3:
      url = "/app/prompt-project?type=1";
      break;
    case 4:
      url = "/app/prompt-project?type=1";
      break;
    default:
      url = "/app/dashboard";
      break;
  }
  return url;
};

export function sortByLanguage (array, preferredLanguage) {
  const preferred = [];
  const english = [];
  const others = [];

  // Group the items based on language
  array.forEach(item => {
    if (item.language?.toLowerCase() === preferredLanguage?.toLowerCase()) {
      preferred.push(item);
    } else if (item.language?.toLowerCase().includes('english')) {
      english.push(item);
    } else {
      others.push(item);
    }
  });

  // Sort the 'others' group by language
  others.sort((a, b) => a.language.localeCompare(b.language));

  // Concatenate the groups in the desired order
  return [...preferred, ...english, ...others];
}

export const sortUserList = (data, value) => {
  return sortBy(data, (item) => {
    const match = item[value].match(/(\d+)@fbai.com/);
    return match ? parseInt(match[1], 10) : 0;
  });
};

export const sortFilesByUnassignFlag = ({ count, files }) => {
  const sortedData = files.sort((a, b) => {
    // First, sort by is_unassign (true should come first)
    if (a.is_unassign !== b.is_unassign) {
      return a.is_unassign ? -1 : 1;
    }
    // Then, sort by createdAt in ascending order
    return moment(a.createdAt).diff(moment(b.createdAt));
  });
  return { count, files: sortedData };
};