import { PROJECT_SUCCESS_LANGUAGE } from "redux/constants";

const initialState = { data: "" };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {
        case PROJECT_SUCCESS_LANGUAGE:
            return { ...state, data: action.payload };

        default:
            return state;
    }
}

