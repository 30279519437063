
import { ADD_FILLER_DATA_FAIL, ADD_FILLER_DATA_SUCCESS, PROJECT_FAIL_FILLER_DATA, PROJECT_SUCCESS_FILLER_DATA, PROJECT_SUCCESS_LABEL_DATA, PROJECT_SUCCESS_TAG_DATA, PROJECT_SUCCESS_TAG_TWO_DATA } from "redux/constants/index";
import { onSuccess, onFailure, onSuccessAllData, onFailureAllData, onSuccessLabelData, onSuccessTagData, onSuccessTagData2 } from "redux/reducers/common";

const initStat = { data: [], allData: [], data3: [], data4: [],data5:[] ,  error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
    switch (action.type) {
        case ADD_FILLER_DATA_SUCCESS:
            return onSuccess(state, action);
        case ADD_FILLER_DATA_FAIL:
            return onFailure(state, action);
        case PROJECT_SUCCESS_FILLER_DATA:
            return onSuccessAllData(state, action);
        case PROJECT_SUCCESS_LABEL_DATA:
            return onSuccessLabelData(state, action);
        case PROJECT_SUCCESS_TAG_DATA:
            return onSuccessTagData(state, action);
        case PROJECT_SUCCESS_TAG_TWO_DATA:
            return onSuccessTagData2(state, action);
        case PROJECT_FAIL_FILLER_DATA:
            return onFailureAllData(state, action);
        default:
            return { ...state };
    }
};
