import { createStore, applyMiddleware, compose } from "redux";
import reducers from "redux/reducers";
import createSagaMiddleware from "redux-saga";
import reduxThunk from "redux-thunk";
const sagaMiddleware = createSagaMiddleware();
// import rootSaga from "../sagas/index";

const middlewares = [sagaMiddleware, reduxThunk];

function configureStore (preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

  // sagaMiddleware.run(rootSaga);

  module.hot.accept("../reducers/index", () => {
    const nextRootReducer = require("../reducers/index");
    store.replaceReducer(nextRootReducer);
  });

  return store;
}

const store = configureStore();

export default store;


